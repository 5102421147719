.report-wrapper {
  position: relative;
  height: 90vh;
  overflow: hidden;
}
.report-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 30px 20px;
  box-shadow: 0 0 1px 0 rgb(0 0 0 / 31%), 0 7px 12px -4px rgb(0 0 0 / 25%);
}
.report-container h3.ant-typography {
    margin-bottom: 1.5em;
}
.report-container .ant-select {
    margin-bottom: 1rem;
}
.report-container .ant-space {
    display: flex;
    width: 100%;
    margin-bottom: 3em;
}