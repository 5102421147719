.loginbg {
  background-color: rgba(255, 255, 255, 0.8);
}
.login-wrapper {
  position: relative;
  height: 100vh;
  overflow: hidden;
}
.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  background-color: #fff;
  padding: 30px 20px;
  box-shadow: 0 0 1px 0 rgb(0 0 0 / 31%), 0 7px 12px -4px rgb(0 0 0 / 25%);
}
.login-container h3 {
  font-size: 24px;
  margin: 0 0 50px 0;
}
.logo-paw {
  margin-bottom: 20px;
}
.text-center {
  text-align: center;
}
.login-gmail {
  display: block;
  text-align: center;
}
