.invoice-card .ant-card {
  border-radius: 8px;
}
.invoice-card .ant-card-head {
  padding: 0;
}
.invoice-card .ant-card-body {
  padding: 16px 16px 8px;
}
.invoice-card .ant-dropdown-link {
  color: #001529;
  font-size: 18px;
  font-weight: bold;
  background-color: #f0f2f5;
  border-radius: 4px;
  padding: 2px 5px;
}
.invoice-card h5.ant-typography {
  margin-bottom: 0;
}
.invoice-card .ant-typography {
  margin-bottom: 0.5em;
}
.invoice-card .ant-card-head-title {
  padding: 10px 16px;
  background: #f0f2f5;
}
/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f0f2f5;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}
.PartnerInvoiceWrapper {
  height: 100%;
  width: 100%;
}
.loader-spin {
  margin: 10px 0;
  text-align: center;
}
.card-header-row {
  margin-bottom: 20px;
}
.stat-wrapper {
  padding: 8px 12px;
  background-color: #f0f2f5;
  border-radius: 8px;
  margin-bottom: 16px;
}
.stat-wrapper .ant-typography {
  margin-bottom: 0;
}
