.btn-grad {background-image: linear-gradient(90deg, #926f5e 10%, #a56969 100%) !important}
.btn-grad {
  margin: 10px !important;
  display: block !important;
  padding: 8px 16px !important;
  width: 120px;
  text-align: center !important;
  transition: 0.5s !important;
  background-size: 200% auto !important;
  color: white !important;            
  box-shadow: 0 0 20px #eee !important;
  border-radius: 10px !important;
  display: block;
  height:auto !important;
}

.btn-grad:hover {
  background-position: right center !important; 
  color: #fff !important;
  text-decoration: none !important;
}

@primary-color: #ab4f43;@link-color: #ab4f43;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 5px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08) 0 9px 28px 8px rgba(0, 0, 0, 0.05);